import React, { useState, useContext } from "react";
import Investor from "./Investor";
import Exprience from "./ExpirenceGroup";
import Terminal from "./Terminal";
import Nvest from "./Nvest";
import logo4 from "../Image/inner4.png";
import Navbar from "./Navbar";
import upper from "../Image/logo/down.svg";
import logo2 from "../Image/inner2.png";
import logo1 from "../Image/logo1.png";
import web3 from "../Image/logo/web3.png";
import logo3 from "../Image/inner8.png";
import { MainContext } from "../Component/Context";
export default function Holiding() {
  const [show, setshow] = useState("default");
  const { openholding, setopenholding } = useContext(MainContext);
  const handle = () => {
    switch (openholding) {
      case "invest":
        return <Investor setshow={setopenholding} />;
      // case "web3":
      //   return <Exprience setshow={setopenholding} />;
      case "termial":
        return <Terminal setshow={setopenholding} />;
      case "web3":
        return <Nvest setshow={setopenholding} />;
      case "default":
        return (
          <>
            <div className="HoldingPage">
              <h1>Our Holdings</h1>

              <div
                className="titleMainCustom titleMain"
                onClick={() => setopenholding("invest")}
              >
                <div className="inner">
                  <img src={logo1} />
                  <h2>IndianInvestor</h2>
                </div>
                <img src={upper} />
              </div>
              <div
                className="titleMainCustom titleMain"
                onClick={() => setopenholding("web3")}
              >
                <div className="inner">
                  <img src={web3} />
                  <h2>Web3Today</h2>
                </div>
                <img src={upper} />
              </div>
              <div
                className="titleMainCustom titleMain"
                onClick={() => setopenholding("termial")}
              >
                <div className="inner">
                  <img src={logo3} />
                  <h2>T5 Group</h2>
                </div>
                <img src={upper} />
              </div>
              {/* <div
                className="titleMainCustom titleMain"
                onClick={() => setshow("nvest")}
              >
                <div className="inner">
                  <img src={logo4} />
                  <h2>NvestCampus</h2>
                </div>
                <img src={upper} />
              </div> */}
            </div>
          </>
        );
    }
  };
  return (
    <>
      <Navbar />
      {handle()}
    </>
  );
}
