import React, { useEffect, useState, useRef, useContext } from "react";
import OtpInput from 'react-otp-input';
import "./homaPage.scss";
import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { MainContext } from "../Component/Context"
import asset from "../Static/assets";
export default function OtpModel({setpage}) {
    const history = useHistory()
    const { setopencard, setpagemask, settherightcard, setclosedisp, setcloserightdisp, setopenmodel, opemodel, navName } = useContext(MainContext)
    const [otp, setotp] = useState("")
console.log("navName",navName)
    const handleChange = async (otp) => {
        await setotp(otp)

        if (navName === "Portfolio") {
            let result = otp.length
            console.log("propasd", navName)
            if (result > 3 && otp === "1111") {

                history.push("/portfolio")
                setopenmodel(false)
            }
        }
        else if (navName === "Launch") {
            let result = otp.length
            console.log("propasd", navName)
            if (result > 3 && otp === "1111") {

                history.push("/incubation")
                setopenmodel(false)
            }
        }
        else if (navName === "Contact") {
            let result = otp.length
            console.log("propasd", navName)
            if (result > 3 && otp === "1111") {
                history.push("/contact")
                setopenmodel(false)
            }
        }
        else if (navName === "About") {
            let result = otp.length
            console.log("propasd", navName)

            if (result > 3 && otp === "3333") {
           
               // history.push("/contact")
               setpage("Team")
            }
        }
    }
    return (
        <div>
            <Modal
                show={opemodel}
                onHide={() => setopenmodel(false)}
                dialogClassName="Model-home-page"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <img className="inr-img" src={asset.inr} />
                    <p className="text-model">
                        This Page Is Only For INR.Group ShareToken
                    </p>
                    <p className="text-model">
                        Holders. Please Enter Pin.
                    </p>
                    <div className="opt-home">

                        <OtpInput
                            value={otp}
                            onChange={handleChange}
                            numInputs={4}
                            separator={<span> </span>}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
