import React from "react";
import "./Holiding.scss";
import Navbar from "./Navbar";

import inner1 from "../Image/inner1.png";
import logo1 from "../Image/logo1.png";
import upper from "../Image/logo/upper.svg";

import small2 from "../Image/small2.png";
import small3 from "../Image/small3.png";
import small4 from "../Image/small4.png";
import small5 from "../Image/small5.png";
import small6 from "../Image/small6.png";
export default function Holiding({setshow}) {
  return (
    <>
      <Navbar />
      <div className="HoldingPage">
        <h1>Our Holdings</h1>

        <div className="titleMain">
          <div className="inner">
            <img src={logo1} />
            <h2>IndianInvestor</h2>
          </div>
          <img src={upper} onClick={()=>setshow("default")} />
        </div>

        <div className="title">
          <div className="tileName">Company</div>
          <div className="tileName">Overview</div>
          <div className="tileName">Status</div>
          <div className="tileName">Position</div>
          <div className="tileName">Website</div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={inner1} />
              <h6>IndianInvestor.com</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
              IndianInvestor.com is a capital markets platform which offer and
              end to end solution for Indian.
            </p>
          </div>
          <div className="contentItem">
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small2} />
              <h6>RealtyMarket</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
              RealtyMarket is a set of prop tech applications for landlords,
              property sellers and construction companies.
            </p>
          </div>
          <div className="contentItem">
            <h5>Development</h5>
          </div>
          <div className="contentItem">
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>


        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small3} />
              <h6>MarketsVerse</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            MarketsVerse is enabling the metaverse for financial services via their proprietary capital market protocols.
            </p>
          </div>
          <div className="contentItem">
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>

        
        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small4} />
              <h6>StartupBrokers</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            StartupBrokers is a one stop shop for startups looking to purchase legal, accounting and branding services.
            </p>
          </div>
          <div className="contentItem">
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>

     
        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small5} />
              <h6>Banker</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            Aiming to be India’s first bond origination platform for private companies issuing debt to retail investors.
            </p>
          </div>
          <div className="contentItem">
            <h5>Development</h5>
          </div>
          <div className="contentItem">
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>



        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small6} />
              <h6>IndianMarket</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A plugin to IndianInvestor that allows foreign investors to participate in its secondary markets 
            </p>
          </div>
          <div className="contentItem">
            <h5>Development</h5>
          </div>
          <div className="contentItem">
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>
      </div>
    </>
  );
}
