import React, { createRef, useEffect, useState } from 'react'
import './Members.scss'
import ScrollSpy from 'react-scrollspy';


export default function Members() {
    
    const [thedisp, setdisp] = useState("none")
    const changeNavbarColor = () => {
     
        if (window.scrollY < 0) {
            setdisp("none")
            // setnavdata(activenav)
        } else {
            setdisp("")
        }
    };
    
    window.addEventListener('click', changeNavbarColor);
   // window.addEventListener('scroll', changeNavbarColor);
    return (
        <div style={{ display: thedisp }}>
            <ScrollSpy className='member-nav' items={['Direct',  'Viral Group', "Experiences Group", 'Employment Group']} currentClassName="isCurrent">
                <li><a href="#Direct" ref={React.createRef()} className='member-text'>Direct</a></li>
                <li><a href="#Viral Group" ref={React.createRef()} className='member-text'>Viral Group</a></li>
                <li><a href="#Experiences Group" ref={React.createRef()} className='member-text'> Experiences Group</a></li>
             <li><a href="#Employment Group" ref={React.createRef()} className='member-text'> Employment Group</a></li>
            
            </ScrollSpy>
        </div>
    )
}
