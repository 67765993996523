import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
//import "react-tooltip/dist/react-tooltip.css";
import MapChart from "../Component/Countries";
import Toggle from "../Component/MapCorosule";
import "./Countries.scss";
import Navbar from "./Navbar";
function WorldMap() {
  const [content, setContent] = useState("");
  console.log("country", content);
  return (
    <>
      <Navbar />
      {/* <NavBarcustom/> */}
      <div className="GoogleMap">
        <MapChart setTooltipContent={setContent} />
        <ReactTooltip place="top">{content}</ReactTooltip>
        <Toggle />
      </div>
    </>
  );
}

export default WorldMap;
