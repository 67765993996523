import React, { useEffect } from "react";
import "./Incubation.scss";
import IncubIcon from "../Image/incubicon.svg";
import StarterIcon from "../Image/startersicon.svg";
import Nav from './Navabar'
import Banner from './Carer'
import CountUp from 'react-countup';
import RightArrow from "../Image/rightarroqw.svg";
import OtpModel from "./OtpModel";
function Incubation() {
    // useEffect(() => {
    //     document.body.style.overflowX = "hidden";
    //     window.scrollTo(0, 0)
    // }, []);
    return (
        <>
            <Banner />
            <Nav active="Starter" />
            <div style={{ overflowX: "hidden" }}>
                <section id="1">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="leftdata">
                                <img src={StarterIcon} alt="starter-icon" className="starterimg" />
                                <p className="the-title">India’s Crypto Venture Studio</p>
                                <p className="the-desc"> <span className="desc-cap">Starters</span> Is Our In-House Startup Inbucation Program That Allows For Aspiring
                                    Entrepreneurs To Lead The Companies That Will Shape The India’s Future.</p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="rightimg">
                                <img src={IncubIcon} alt="incub-icon" className="personimg" />
                                <hr className="the-hrtag" />
                            </div>
                        </div>
                    </div>
                </section>
                <section id="2">
                    <div className="second-page">
                        <div className="nums-second-data">
                            <div className="nums">
                                <p className="num-title">0<CountUp end={7} duration={2}></CountUp></p>
                                <p className="num-text">Founders</p>
                            </div>
                            <div className="nums">
                                <p className="num-title"><CountUp end={16} duration={2} /></p>
                                <p className="num-text">Founders</p>
                            </div>
                            <div className="nums">
                                <p className="num-title num-align"><CountUp end={75} duration={2} /></p>
                                <p className="num-text">Team Members</p>
                            </div>
                            <div className="nums">
                                <p className="num-title">₹<CountUp end={20.4} duration={2} decimals={1} />CR</p>
                                <p className="num-text cap-align">Capital Invested</p>
                            </div>
                        </div>
                        <hr />
                        <div className="second-page-down">
                            <p className="title-sec">Why Starter?</p>
                            <p className="sec-desc">INR.Group develops category-defining businesses and brands, utilizing and
                                cultivating each of its globally regarded domain names. The company combines these original,
                                premium domain names with established enterprises, experienced entrepreneurs and growing startups
                                across a vast spectrum of products and/or services. Starter serves as a builder, incubator, investor,
                                partner, consultant, accelerator, and/or promoter.</p>
                        </div>
                    </div>
                </section>
                <section id="3">
                    <div className="third-page">
                        <p className="third-text">How Does Starter Work?</p>
                        {/* <hr className="the-hr-tag" /> */}
                        <div className="row thebtm">
                            <div className="first-row">
                                <div className="col-sm-3">
                                    <div className="align-starter">
                                        <div className="starter-cards">
                                            <p className="digit">1</p>
                                            <p className="starter-title">Browse Brands</p>
                                            <p className="starter-desc">Create, manage, and grow your asset management business with the IFO system.</p>
                                            <div className="arrow-circle">
                                                <img src={RightArrow} alt="right-arrow" className="right-img-arrow" />
                                            </div>
                                            <div className="hov-text">Check</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="align-starter">
                                        {/* <p className="vl"></p> */}
                                        <div className="starter-cards">
                                            <p className="digit">2</p>
                                            <p className="starter-title">Application</p>
                                            <p className="starter-desc">Create, manage, and grow your asset management business with the IFO system.</p>
                                            <div className="arrow-circle">
                                                <img src={RightArrow} alt="right-arrow" className="right-img-arrow" />
                                            </div>
                                            <div className="hov-text">Check</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="align-starter">
                                        {/* <p className="vl"></p> */}
                                        <div className="starter-cards">
                                            <p className="digit">3</p>
                                            <p className="starter-title">Approval</p>
                                            <p className="starter-desc">Create, manage, and grow your asset management business with the IFO system.</p>
                                            <div className="arrow-circle">
                                                <img src={RightArrow} alt="right-arrow" className="right-img-arrow" />
                                            </div>
                                            <div className="hov-text">Check</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="align-starter">
                                        {/* <p className="vl"></p> */}
                                        <div className="starter-cards">
                                            <p className="digit rightnum">4</p>
                                            <p className="starter-title">Temp Offer</p>
                                            <p className="starter-desc">Create, manage, and grow your asset management business with the IFO system.</p>
                                            <div className="arrow-circle">
                                                <img src={RightArrow} alt="right-arrow" className="right-img-arrow" />
                                            </div>
                                            <div className="hov-text">Check</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row btm">
                            <div className="first-row">
                                <div className="col-sm-3">
                                    <div className="align-starter">
                                        <div className="starter-cards1">
                                            <p className="digit">5</p>
                                            <p className="starter-title">Onboarding</p>
                                            <p className="starter-desc">Create, manage, and grow your asset management business with the IFO system.</p>
                                            <div className="arrow-circle">
                                                <img src={RightArrow} alt="right-arrow" className="right-img-arrow" />
                                            </div>
                                            <div className="hov-text">Check</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="align-starter">
                                        <div className="starter-cards1">
                                            <p className="digit">6</p>
                                            <p className="starter-title">Cap Raise</p>
                                            <p className="starter-desc">Create, manage, and grow your asset management business with the IFO system.</p>
                                            <div className="arrow-circle">
                                                <img src={RightArrow} alt="right-arrow" className="right-img-arrow" />
                                            </div>
                                            <div className="hov-text">Check</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="align-starter">
                                        <div className="starter-cards1">
                                            <p className="digit">7</p>
                                            <p className="starter-title">Capitalized</p>
                                            <p className="starter-desc">Create, manage, and grow your asset management business with the IFO system.</p>
                                            <div className="arrow-circle">
                                                <img src={RightArrow} alt="right-arrow" className="right-img-arrow" />
                                            </div>
                                            <div className="hov-text">Check</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="align-starter">
                                        <div className="starter-cards1">
                                            <p className="digit rightnum">8</p>
                                            <p className="starter-title">Full Offer</p>
                                            <p className="starter-desc">Create, manage, and grow your asset management business with the IFO system.</p>
                                            <div className="arrow-circle">
                                                <img src={RightArrow} alt="right-arrow" className="right-img-arrow" />
                                            </div>
                                            <div className="hov-text">Check</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <hr className="hr-style" /> */}

                    </div>
                    <br />
                    <br />
                    <br />
                    <br /><br />
                    <br /><br />
                    <br /><br />
                    <br /><br />

                </section>
                <div className="bottom-align">
                    <p className="bottom-text">Get Paid Up To 6 Lakhs  Per Year To Be A Founder</p>
                </div>
            </div>
            <OtpModel/>
        </>
    )
}

export default Incubation