import React, { useEffect, useRef, useState } from "react";

import "./Navbar.scss";
import air from "../img/logoNav.svg";
import ScrollSpy from "react-scrollspy";
import { useStore } from "../Component/store/store";
import downarrow from "../Static/Image/downarrow.svg";
import xmark from "../img/xmark.svg";
import mobilecross from "../img/mobilecross.svg";
function Navbar({ activeSection }) {
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [visibleKey, setVisibleKey] = useState(false);
  // const observers = useRef([]);

  // const onClick = (item, key) => {
  //   setVisibleKey(key);
  // };

  // const observerCallback = async (e, key) => {
  //   if (e.length && e[0].isIntersecting) {
  //     setVisibleKey(key);
  //   }
  // };

  // useEffect(() => {
  //   if (observerRefs.current?.length && observers.current) {
  //     Array.from(Array(10).keys()).forEach((_u, key) => {
  //       observers.current[key] = new IntersectionObserver((e) =>
  //         observerCallback(e, key)
  //       );
  //       if (observerRefs.current[key]) {
  //         observers.current[key].observe(observerRefs.current[key]);
  //       }
  //     });
  //   }
  //   return () =>
  //     observers.current?.forEach((observer) => observer?.current?.disconnect());
  // }, [observerRefs, observers]);

  const { isOpen, setOpen, navOpen, setNavOpen } = useStore();

  const [disp, setdisp] = useState(false);
  const changeNavbarColor = async () => {
    if (window.scrollY > 60 || isOpen) {
      // setnavdata(activenav)
      // await history.push("/about")
      setdisp(true);
    } else {
      // await history.push("/about/WhatWeDo")
      setdisp(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <>
      <div className="mobile-nav-air">
        <div style={{ display: "flex" }}>
          {drawerOpen ? (
            <img onClick={() => setdrawerOpen(false)} src={xmark} />
          ) : (
            <img onClick={() => setdrawerOpen(true)} src={mobilecross} />
          )}
          <img src={air} style={{ marginLeft: "10px" }} />
          {/* <div className="drop-box">
            <div className="drop-menu-box" onClick={() => setOpen(!isOpen)}>
              <button className="drop-menu">
                BY&nbsp;<span className="drop-menu-head">AIR</span>
              </button>
              <div
                className="drop-img-box"
                style={{ transform: isOpen ? "rotate(180deg)" : "" }}
              >
                <img className="drop-img" src={downarrow} alt="" />
              </div>
            </div>
          </div> */}
        </div>

        <label>Get Started</label>
      </div>
      <div
        className="navigation"
        style={{
          height: "4.5rem",
          background: "#16171A",
        }}
      >
        <div style={{ display: "flex" }}>
          <img src={air} />
          {/* <div className="drop-box">
            <div className="drop-menu-box" onClick={() => setOpen(!isOpen)}>
              <button className="drop-menu">
                BY&nbsp;<span className="drop-menu-head">AIR</span>
              </button>
              <div
                className="drop-img-box"
                style={{ transform: isOpen ? "rotate(180deg)" : "" }}
              >
                <img className="drop-img" src={downarrow} alt="" />
              </div>
            </div>
          </div> */}
        </div>
        <div
          className="left-side-nav"
          onMouseLeave={() => setVisibleKey(false)}
        >
          <ScrollSpy
            className="member-nav"
            items={["n", "Buy Crypto", "Products", "Pricing", "Resources"]}
            offset={-100}
            currentClassName="isCurrent"
          >
            <li>
              <a
                href="n"
                ref={React.createRef()}
                className="member-text-first"
              ></a>
            </li>
            <li onMouseEnter={() => setVisibleKey(false)}>
              <a ref={React.createRef()} className="member-text">
                Buy Crypto
              </a>
           
            </li>
            <li>
              <a 
              onMouseEnter={() => setVisibleKey(true)} ref={React.createRef()} 
              
              className="member-text">
                Products
              </a>
              {visibleKey && (
                <div
                  className="navDropdwown"
                  onMouseLeave={() => setVisibleKey(false)}
                >
                  {/* <h1>Our Product Lines</h1> */}
                  <div
                    className="listDetail"
                    onClick={() => setVisibleKey(false)}
                  >
                    <h2>X3 Exchange</h2>
                    <p>
                    Buy, Sell & Store Crypto In Canada’s Most Secure Wallet
                    </p>
                  </div>
                  <div
                    className="listDetail"
                    onClick={() => setVisibleKey(false)}
                  >
                    <h2>X3 Pay</h2>
                    <p>Spend Your Crypto With Our Card & Earn Rewards </p>
                  </div>
                  <div
                    className="listDetail"
                    onClick={() => setVisibleKey(false)}
                  >
                    <h2>X3 Remit</h2>
                    <p>Send Money Overseas Instantly For Free</p>
                  </div>
                </div>
              )}
            </li>
            <li>
              <a onMouseEnter={() => setVisibleKey(false)} ref={React.createRef()} className="member-text">
              Affiliates
              </a>
            </li>

            <li>
              <a
          onMouseEnter={() => setVisibleKey(false)}
                ref={React.createRef()}
                className="member-text"
              >
                Resources
              </a>
            </li>
          </ScrollSpy>

          <div className="nav-button-handle">
            <label
              className="nav-invester"
              onClick={() => {
                window.open("https://otc.x3.money", "_blank");
              }}
            >
              Login
            </label>
            <label
              className="Affiliates-nav"
              onClick={() => {
                window.open("https://otc.x3.money/register", "_blank");
              }}
            >
              Get Started
            </label>
          </div>
        </div>
      </div>
      {drawerOpen && (
        <div className="mobileDrawer">
          <ScrollSpy
            className="member-nav"
            items={["Mision", "Apps", "Opportunities", "Influencers"]}
            offset={-100}
            currentClassName="isCurrent"
          >
            <li
              onClick={() => setdrawerOpen(false)}
              style={{
                background: "rgba(22, 23, 26, 0.05)",
                borderRadius: " 10px",
              }}
            >
              <a href="#Mision" ref={React.createRef()} className="member-text">
                Buy Crypto
              </a>
            </li>
            <li onClick={() => setdrawerOpen(false)}>
              <a href="#Apps" ref={React.createRef()} className="member-text">
                Products
              </a>
            </li>
            <li onClick={() => setdrawerOpen(false)}>
              <a
                href="#Opportunities"
                ref={React.createRef()}
                className="member-text"
              >
                Affiliates
              </a>
            </li>
            <li onClick={() => setdrawerOpen(false)}>
              <a
                href="#Influencers"
                ref={React.createRef()}
                className="member-text"
              >
                {" "}
                Resources
              </a>
            </li>
          </ScrollSpy>

          <div className="nav-button-handle">
            {/* <label
              className="nav-invester"
              onClick={() => {
                setdrawerOpen(false);
                window.open("https://aiprowallet.com/", "_blank");
              }}
            >
              For Investors
            </label> */}
            <label
              className="Affiliates-nav"
              // onClick={() => {
              //   setdrawerOpen(false);
              //   window.open("https://viral.group/", "_blank");
              // }}
            >
              Get Started
            </label>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
