import React, { useState, useEffect, useContext } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import logo from "../Image/logo.png";
import mobo from '../Image/mobo.png'
import closemark from "../Image/closemark.png";
import "./Navbar.scss";
import { useHistory } from "react-router-dom";
import { MainContext } from "../Component/Context"
export default function NavSection({ active }) {
  const { setopencard, setpagemask, settherightcard, setclosedisp, setnavName, setopenmodel } = useContext(MainContext)
  console.log("zxczxcxzc", active)
  const [info, setinfo] = useState("About");
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [navExpanded, satnavExpanded] = useState(false);
  const handleFunction = () => {
    history.push("/Events/Cities");
  };
  const handleFunction1 = () => {
    setExpanded(!expanded);
    history.push("/Events/Cities");
  };

  const hanldeRoute = () => {
    setnavName("Portfolio")
    setopenmodel(true)

  }
  const Contact = () => {
    setnavName("Contact")
    setopenmodel(true)
  }

  const holding = () => {

    history.push("/holdings")
  }

  const hanldeRoutePad = () => {
    setnavName("Launch")
    setopenmodel(true)

  }
  const meta = [
    {
      name: "About",
    },
    {
      name: "Holdings",
    },
    {
      name: "Launch Pad",
    },
    {
      name: "Portfolio",
    },
    {
      name: "Contact"
    }

  ];

  const data = [
    {
      name: "About",
    },
    {
      name: "Portfolio",
    },
    {
      name: "Services",
    },
    {
      name: "Incubation",
    },
    {
      name: "IMVerse"
    }
  ];
  const [store, setstore] = useState([]);

  useEffect(() => {

    setmedata("");

    setstore(meta);

    return () => { };
  }, [info]);


  const handler = async (e) => {
    if (e === "Launch Pad" || e === "Portfolio") {

    }
    else {

      setExpanded(!expanded);
      if (e === "About") {
        await setinfo(e);

        history.push(`/about`);
      }

      if (e === "Contact") {
        await setinfo(e);
        
        history.push('/contact')
      }
      
      if (e === "Holdings") {
        await setinfo(e);
        history.push('/holdings')
      }
      if (e === "Portfolio") {
        await setinfo(e);

      }
      if (e === "Launch Pad") {


      }
      await setinfo(e);

    }
  };
  console.log("asdazzxczxc", info)
  const handler1 = async (e) => {


    active = e;
    if (e === "About") {
      await setinfo(e);
      history.push(`/about`);
    }
    if (e === "Portfolio") {
      await setinfo(e);
      history.push(`/investors/portfolio/sharetokens`);
    }
    if (e === "Starters") {
      await setinfo(e);
      history.push(`/incubation`);
    }

  };

  const aboutpage = () => {
  //  alert()
    history.push(`/about`);
    setnavName("About")
  }

  const starterpage = (e) => {
    history.push('/incubation')
  }

  const core = (e) => {
    history.push('/holdings')
  }
  const foliopage = (e) => {
    history.push("/portfolio")
  }

  const setNavExpanded = (expanded) => {
    satnavExpanded(expanded);
  };
  const [medata, setmedata] = useState("");
  const metamobilefun = () => {
    history.push("/metaversify");
    setmedata("meta");

    setExpanded(!expanded);
    setinfo("meta");
  };

  const metdeskfun = () => {
    history.push("/metaversify");
    setmedata("meta");

    setinfo("meta");
  };
  return (
    <>
      {
        expanded ?
          <div className="blackscreen">

          </div>
          : ""
      }
      <div className="custom_navbar1e mobo">
        <Navbar
          onToggle={setNavExpanded}
          expanded={expanded}
          expand="lg"
          variant="dark"
        >
          <Container fluid className="p-0 mobile">
            <Navbar.Brand href="/">
              {" "}
              <img src={logo} alt="" style={{ width: "225px", height: "40.26px" }} />
            </Navbar.Brand>
            {expanded ? (
              <Navbar.Toggle
                onClick={() => setExpanded(!expanded)}
                aria-controls="offcanvasNavbar"
              >
                <img style={{ width: "17px" }} src={closemark} alt="" />
              </Navbar.Toggle>
            ) : (
              <Navbar.Toggle
                onClick={() => setExpanded(!expanded)}
                aria-controls="offcanvasNavbar"
              />
            )}

            <img
              className="edit"
              onClick={() => history.push(`/`)}
              style={{ width: "225px", height: "40.26px" }}
              alt=""
              src={mobo}
            />

            {expanded ? (
              <Navbar.Collapse id="responsive-navbar-nav">
                {/* <Nav className="me-auto">
   
    
    </Nav> */}
                <Nav>
                  <>
                    {store.map((item) => {
                      return (
                        <>
                          <h6
                            onClick={() => handler(item.name)}
                            style={
                              item.name === "Launch Pad" || item.name === "Portfolio" ? { cursor: "not-allowed",opacity: "0.5", fontSize: "22px "  } :
                                info === item.name
                                  ? { fontWeight: "bold", fontSize: "30px " } :
                                  { opacity: "0.5", fontSize: "22px " }
                            }
                          >
                            {item.name}
                          </h6>
                        </>
                      );
                    })}
                    <div className='sectionlabel-mobile'>

                      <label className='label1' onClick={() => window.open("https://indianinvestor.com/", "_blank")}> Investors</label>

                    </div>

                  </>
                </Nav>

              </Navbar.Collapse>
            ) : (
              ""
            )}
          </Container>
        </Navbar>
      </div>

      <div className="custom_navbar1e desk">
        <Navbar expand="lg" variant="dark">
          <Container fluid className="p-0 mobile">
            <Navbar.Brand href="/" className="img-space">
              {" "}
              <img src={logo} alt="" style={{ width: "225px", height: "40.26px", marginTop: "10px" }} />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="offcanvasNavbar" />
            <div className='vl-top'></div>
            <input type="text" placeholder="Search INR Group...." className="searchsty" />


            <img className="edit" style={{ width: "225px", height: "40.26px" }} src={logo} alt="" />

            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                {/* {store.map((item) => {
                  return (
                    <>
                      <div className='vl-topnav'></div>
                      <Nav.Link
                        className={"menu-items " + (active === item.name ? "active" : "")}
                        onClick={() => handler1(item.name)}
                        style={
                          info === item.name
                            ? { fontWeight: "bold" }
                            : { opacity: "1" }
                        }
                      >
                        {item.name}
                      </Nav.Link>
                    </>
                  );
                })} */}
                <div className="vl-topnav"></div>
                <Nav.Link to="/about" onClick={aboutpage} className={"menu-items " + (active === "About" ? "active" : "")}>About</Nav.Link>
                <div className="vl-topnav"></div>
                <Nav.Link onClick={holding} className={"menu-items " + (active === "corebrand" ? "active" : "")}  >Holdings</Nav.Link>
                <div className="vl-topnav"></div>
                <Nav.Link onClick={hanldeRoutePad} className={"menu-items " + (active === "Starter" ? "active" : "")}>Launch Pad</Nav.Link>
                {/* <div className="vl-topnav"></div>
                <Nav.Link className="menu-items">Accelerator</Nav.Link> */}
                <div className="vl-topnav"></div>
                <Nav.Link onClick={hanldeRoute} className={"menu-items " + (active === "Portfolio" ? "active" : "")}>Portfolio</Nav.Link>


                <div className="vl-topnav"></div>
                <Nav.Link
                  onClick={Contact}
                  className={"menu-items " + (active === "Contact" ? "active" : "")}

                >Contact</Nav.Link>
                <div className="vl-topnav"></div>
                <div className="investor-class" onClick={() => window.open("https://indianinvestor.com/", "_blank")} style={{ backgroundColor: "#4CAF50", color: "#fff", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                  <h5

                    style={
                      { margin: "0", fontSize: "15px", color: "#fff", fontWeight: "700", marginTop: "9px" }
                    }
                  >
                    Investor
                  </h5>
                </div>
                {/* <div className="vl-topnav"></div>
                <Nav.Link className="menu-items">Services</Nav.Link>
                <div className="vl-topnav"></div>
                <Nav.Link className="menu-items">Media</Nav.Link> */}
              </Nav>

            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}
