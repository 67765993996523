import React from 'react';
import './trydata.scss';

import { useParallax, ParallaxProvider } from 'react-scroll-parallax';
// import { ParallaxProvider } from "react-scroll-parallax";
export default function Index() {
//   const cat = useParallax({
//     scale: [0.5, 1, 'easeInQuad'],
//   });
//   const dog = useParallax({
//     scaleX: [1, 0, 'easeInQuad'],
//   });
  const rabbit = useParallax({
    scale: [1, 2, 'easeInQuad'],
    rotate: [30, 0],
    translateX: [0,1],
    // translateX: [23, 0, "easeOutQuint"],
  });
  return (
    <>
      <div className="innder">
        <div className="container-body">
          <h1>
            Enter the Portal to The Multiverse. One Super App. Trusted by
            millions. Everything finance. Debit cards. Friends, chat and social.
          </h1>
          <div className="images-wrapper">
            {/* <div className="cat">
              <img
                ref={cat.ref}
                src="https://images.pexels.com/photos/1404819/pexels-photo-1404819.jpeg?auto=compress&cs=tinysrgb&w=200"
              />
            </div> */}
            {/* <div className="dog">
              <img
                ref={dog.ref}
                src="https://images.pexels.com/photos/3361739/pexels-photo-3361739.jpeg?auto=compress&cs=tinysrgb&w=200"
              />
            </div> */}
            <div className="rabbit">
              <img
                ref={rabbit.ref}
                src="https://xportal.com/phone-home.e9ab98a1.png"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
