import React from "react";
import "./home.scss";
import ReactTypingEffect from "react-typing-effect";
export default function HomePage() {
  return (
    <>
      <div className="Sectionlanding">
        <div className="redtinit"></div>
        {/*  */}

        <ul class="elements-bar left -unlist">
          <li>
            <a class="scroll-top dynamic-typo -undash -small-t vc_hidden-md">
              <div class="scroll-top-bar">
                {/* <div class="scroll-track" style="width: 12.54%;"></div> */}
              </div>
              <div class="scroll-top-holder titles-typo title">
                Scroll to top{" "}
              </div>
            </a>
          </li>
          <li>
            <div class="color-switcher dynamic-typo cursor-as-pointer color-switcher-mobile dark">
              <div class="color-switcher-item dark">
                <div class="color-switcher-item-state">
                  <span class="caption">Dark</span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.66222 3.23111C6.50222 3.8 6.42222 4.39556 6.42222 5C6.42222 8.62667 9.37333 11.5778 13 11.5778C13.6044 11.5778 14.2 11.4978 14.7689 11.3378C13.8444 13.6133 11.6044 15.2222 9 15.2222C5.56889 15.2222 2.77778 12.4311 2.77778 9C2.77778 6.39556 4.38667 4.15556 6.66222 3.23111ZM9 1C4.58222 1 1 4.58222 1 9C1 13.4178 4.58222 17 9 17C13.4178 17 17 13.4178 17 9C17 8.59111 16.9644 8.18222 16.9111 7.79111C16.04 9.00889 14.6178 9.8 13 9.8C10.3511 9.8 8.2 7.64889 8.2 5C8.2 3.39111 8.99111 1.96 10.2089 1.08889C9.81778 1.03556 9.40889 1 9 1Z"></path>
                  </svg>
                </div>
              </div>
              {/* <div class="color-switcher-item light">
            <div class="color-switcher-item-state">
                <span class="caption">Light</span>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 6.75C10.2375 6.75 11.25 7.7625 11.25 9C11.25 10.2375 10.2375 11.25 9 11.25C7.7625 11.25 6.75 10.2375 6.75 9C6.75 7.7625 7.7625 6.75 9 6.75ZM9 5.25C6.93 5.25 5.25 6.93 5.25 9C5.25 11.07 6.93 12.75 9 12.75C11.07 12.75 12.75 11.07 12.75 9C12.75 6.93 11.07 5.25 9 5.25ZM1.5 9.75H3C3.4125 9.75 3.75 9.4125 3.75 9C3.75 8.5875 3.4125 8.25 3 8.25H1.5C1.0875 8.25 0.75 8.5875 0.75 9C0.75 9.4125 1.0875 9.75 1.5 9.75ZM15 9.75H16.5C16.9125 9.75 17.25 9.4125 17.25 9C17.25 8.5875 16.9125 8.25 16.5 8.25H15C14.5875 8.25 14.25 8.5875 14.25 9C14.25 9.4125 14.5875 9.75 15 9.75ZM8.25 1.5V3C8.25 3.4125 8.5875 3.75 9 3.75C9.4125 3.75 9.75 3.4125 9.75 3V1.5C9.75 1.0875 9.4125 0.75 9 0.75C8.5875 0.75 8.25 1.0875 8.25 1.5ZM8.25 15V16.5C8.25 16.9125 8.5875 17.25 9 17.25C9.4125 17.25 9.75 16.9125 9.75 16.5V15C9.75 14.5875 9.4125 14.25 9 14.25C8.5875 14.25 8.25 14.5875 8.25 15ZM4.4925 3.435C4.2 3.1425 3.72 3.1425 3.435 3.435C3.1425 3.7275 3.1425 4.2075 3.435 4.4925L4.23 5.2875C4.5225 5.58 5.0025 5.58 5.2875 5.2875C5.5725 4.995 5.58 4.515 5.2875 4.23L4.4925 3.435ZM13.77 12.7125C13.4775 12.42 12.9975 12.42 12.7125 12.7125C12.42 13.005 12.42 13.485 12.7125 13.77L13.5075 14.565C13.8 14.8575 14.28 14.8575 14.565 14.565C14.8575 14.2725 14.8575 13.7925 14.565 13.5075L13.77 12.7125ZM14.565 4.4925C14.8575 4.2 14.8575 3.72 14.565 3.435C14.2725 3.1425 13.7925 3.1425 13.5075 3.435L12.7125 4.23C12.42 4.5225 12.42 5.0025 12.7125 5.2875C13.005 5.5725 13.485 5.58 13.77 5.2875L14.565 4.4925ZM5.2875 13.77C5.58 13.4775 5.58 12.9975 5.2875 12.7125C4.995 12.42 4.515 12.42 4.23 12.7125L3.435 13.5075C3.1425 13.8 3.1425 14.28 3.435 14.565C3.7275 14.85 4.2075 14.8575 4.4925 14.565L5.2875 13.77Z"></path></svg>
            </div>
        </div> */}
              <div
                class="color-switcher-toddler"
                style={{
                  background: "#4a4653",
                  borderRadius: "100px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  color: "white",
                }}
              >
                <div class="color-switcher-toddler-wrap">
                  {/* <div class="color-switcher-toddler-item dark">
                    <div class="color-switcher-item-state">
                        <span class="caption">Dark</span>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.66222 3.23111C6.50222 3.8 6.42222 4.39556 6.42222 5C6.42222 8.62667 9.37333 11.5778 13 11.5778C13.6044 11.5778 14.2 11.4978 14.7689 11.3378C13.8444 13.6133 11.6044 15.2222 9 15.2222C5.56889 15.2222 2.77778 12.4311 2.77778 9C2.77778 6.39556 4.38667 4.15556 6.66222 3.23111ZM9 1C4.58222 1 1 4.58222 1 9C1 13.4178 4.58222 17 9 17C13.4178 17 17 13.4178 17 9C17 8.59111 16.9644 8.18222 16.9111 7.79111C16.04 9.00889 14.6178 9.8 13 9.8C10.3511 9.8 8.2 7.64889 8.2 5C8.2 3.39111 8.99111 1.96 10.2089 1.08889C9.81778 1.03556 9.40889 1 9 1Z"></path></svg>
                    </div>
                </div> */}
                  <div class="color-switcher-toddler-item light">
                    <div class="color-switcher-item-state">
                      <span class="caption">Light</span>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9 6.75C10.2375 6.75 11.25 7.7625 11.25 9C11.25 10.2375 10.2375 11.25 9 11.25C7.7625 11.25 6.75 10.2375 6.75 9C6.75 7.7625 7.7625 6.75 9 6.75ZM9 5.25C6.93 5.25 5.25 6.93 5.25 9C5.25 11.07 6.93 12.75 9 12.75C11.07 12.75 12.75 11.07 12.75 9C12.75 6.93 11.07 5.25 9 5.25ZM1.5 9.75H3C3.4125 9.75 3.75 9.4125 3.75 9C3.75 8.5875 3.4125 8.25 3 8.25H1.5C1.0875 8.25 0.75 8.5875 0.75 9C0.75 9.4125 1.0875 9.75 1.5 9.75ZM15 9.75H16.5C16.9125 9.75 17.25 9.4125 17.25 9C17.25 8.5875 16.9125 8.25 16.5 8.25H15C14.5875 8.25 14.25 8.5875 14.25 9C14.25 9.4125 14.5875 9.75 15 9.75ZM8.25 1.5V3C8.25 3.4125 8.5875 3.75 9 3.75C9.4125 3.75 9.75 3.4125 9.75 3V1.5C9.75 1.0875 9.4125 0.75 9 0.75C8.5875 0.75 8.25 1.0875 8.25 1.5ZM8.25 15V16.5C8.25 16.9125 8.5875 17.25 9 17.25C9.4125 17.25 9.75 16.9125 9.75 16.5V15C9.75 14.5875 9.4125 14.25 9 14.25C8.5875 14.25 8.25 14.5875 8.25 15ZM4.4925 3.435C4.2 3.1425 3.72 3.1425 3.435 3.435C3.1425 3.7275 3.1425 4.2075 3.435 4.4925L4.23 5.2875C4.5225 5.58 5.0025 5.58 5.2875 5.2875C5.5725 4.995 5.58 4.515 5.2875 4.23L4.4925 3.435ZM13.77 12.7125C13.4775 12.42 12.9975 12.42 12.7125 12.7125C12.42 13.005 12.42 13.485 12.7125 13.77L13.5075 14.565C13.8 14.8575 14.28 14.8575 14.565 14.565C14.8575 14.2725 14.8575 13.7925 14.565 13.5075L13.77 12.7125ZM14.565 4.4925C14.8575 4.2 14.8575 3.72 14.565 3.435C14.2725 3.1425 13.7925 3.1425 13.5075 3.435L12.7125 4.23C12.42 4.5225 12.42 5.0025 12.7125 5.2875C13.005 5.5725 13.485 5.58 13.77 5.2875L14.565 4.4925ZM5.2875 13.77C5.58 13.4775 5.58 12.9975 5.2875 12.7125C4.995 12.42 4.515 12.42 4.23 12.7125L3.435 13.5075C3.1425 13.8 3.1425 14.28 3.435 14.565C3.7275 14.85 4.2075 14.8575 4.4925 14.565L5.2875 13.77Z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        {/*  */}
        {/*  */}
        <ul class="elements-bar right -unlist">
          <li>
            <div class="social-bar dynamic-typo">
              <ul class="social-bar-holder titles-typo -small-t -unlist vc_hidden-md">
                <li>Follow Us</li>
                <li>—</li>
                <li>
                  <a class="-undash facebook" target="_blank" rel="nofollow">
                    Fb.{" "}
                  </a>
                </li>
                <li>
                  <a class="-undash behance" target="_blank" rel="nofollow">
                    Be.{" "}
                  </a>
                </li>
                <li>
                  <a class="-undash youtube" target="_blank" rel="nofollow">
                    Yt.{" "}
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        {/*  */}

        <div className="sectiontextbackground">
          strategy
          <br />
          design
          <br />
          progress
          <br />
          statements
        </div>

        <div className="content">
          <div>
            <p>Building Capabilities</p>
            <h1>
              Enabling <br /> organizations <br />
              <ReactTypingEffect text={["to grow.", "to gain"]} />
            </h1>
            <div className="readMore">
              Read More
              <svg
                class="default"
                width="16"
                height="16"
                fill="#fff"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"></path>
              </svg>
            </div>
          </div>

          <button class="icon-button -large">
            <i class="icon">
              <svg
                class="default"
                width="13"
                height="20"
                viewBox="0 0 13 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 20L13 10L0 0V20Z"></path>
              </svg>
            </i>
          </button>
        </div>
      </div>
<div className="secondMain">
      <div className="secondSection">
        <div className="insidesecondSection">
          <img
            src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/project__01.jpeg"
            alt="Stickers Pack"
          />
          <div class="overlay"></div>
          <div className="inner">
            <button class="icon-button -large">
              <i class="icon">
                <svg
                  class="default"
                  width="13"
                  height="20"
                  viewBox="0 0 13 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 20L13 10L0 0V20Z"></path>
                </svg>
              </i>
            </button>
            <div className="textinner">
              <h1>Stickers Pack </h1>

              <div className="blowsectiom">
                <h4>
                  Show project <div className="line"></div>{" "}
                </h4>
                <h5>Identity, Mockup</h5>
              </div>
            </div>
          </div>

        </div>


        <div className="insidesecondSection largerinise">
          <img
            src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/oh__img25-1920x1076.jpeg"
            alt="Stickers Pack"
          />
          <div class="overlay"></div>
          <div className="inner">
            {/* <button class="icon-button -large">
              <i class="icon">
                <svg
                  class="default"
                  width="13"
                  height="20"
                  viewBox="0 0 13 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 20L13 10L0 0V20Z"></path>
                </svg>
              </i>
            </button> */}
            <div className="textinner">
              <h1>Stickers Pack </h1>

              <div className="blowsectiom">
                <h4>
                  Show project <div className="line"></div>{" "}
                </h4>
                <h5>Identity, Mockup</h5>
              </div>
            </div>
          </div>

        </div>

      </div>



{/*  */}

      <div className="secondSection">
        <div className="insidesecondSection threeinside">
          <img
            src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/oh__img30.jpeg"
            alt="Stickers Pack"
          />
          <div class="overlay"></div>
          <div className="inner">
            {/* <button class="icon-button -large">
              <i class="icon">
                <svg
                  class="default"
                  width="13"
                  height="20"
                  viewBox="0 0 13 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 20L13 10L0 0V20Z"></path>
                </svg>
              </i>
            </button> */}
            <div className="textinner">
              <h1>Stickers Pack </h1>

              <div className="blowsectiom">
                <h4>
                  Show project <div className="line"></div>{" "}
                </h4>
                <h5>Identity, Mockup</h5>
              </div>
            </div>
          </div>

        </div>
        <div className="insidesecondSection threeinside">
          <img
            src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/oh__img35.jpeg"
            alt="Stickers Pack"
          />
          <div class="overlay"></div>
          <div className="inner">
            <button class="icon-button -large">
              <i class="icon">
                <svg
                  class="default"
                  width="13"
                  height="20"
                  viewBox="0 0 13 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 20L13 10L0 0V20Z"></path>
                </svg>
              </i>
            </button> 
            <div className="textinner">
              <h1>Stickers Pack </h1>

              <div className="blowsectiom">
                <h4>
                  Show project <div className="line"></div>{" "}
                </h4>
                <h5>Identity, Mockup</h5>
              </div>
            </div>
          </div>

        </div>

        <div className="insidesecondSection threeinside">
          <img
            src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/oh__img26.jpeg"
            alt="Stickers Pack"
          />
          <div class="overlay"></div>
          <div className="inner">
            <button class="icon-button -large">
              <i class="icon">
                <svg
                  class="default"
                  width="13"
                  height="20"
                  viewBox="0 0 13 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 20L13 10L0 0V20Z"></path>
                </svg>
              </i>
            </button> 
            <div className="textinner">
              <h1>Stickers Pack </h1>

              <div className="blowsectiom">
                <h4>
                  Show project <div className="line"></div>{" "}
                </h4>
                <h5>Identity, Mockup</h5>
              </div>
            </div>
          </div>

        </div>

      </div>






      <div className="secondSection">
    


        <div className="insidesecondSection largerinise">
          <img
            src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/oh__img95-1920x1076.jpeg"
            alt="Stickers Pack"
          />
          <div class="overlay"></div>
          <div className="inner">
            {/* <button class="icon-button -large">
              <i class="icon">
                <svg
                  class="default"
                  width="13"
                  height="20"
                  viewBox="0 0 13 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 20L13 10L0 0V20Z"></path>
                </svg>
              </i>
            </button> */}
            <div className="textinner">
              <h1>Stickers Pack </h1>

              <div className="blowsectiom">
                <h4>
                  Show project <div className="line"></div>{" "}
                </h4>
                <h5>Identity, Mockup</h5>
              </div>
            </div>
          </div>

        </div>


        <div className="insidesecondSection">
          <img
            src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/oh__img31.jpeg"
            alt="Stickers Pack"
          />
          <div class="overlay"></div>
          <div className="inner">
            <button class="icon-button -large">
              <i class="icon">
                <svg
                  class="default"
                  width="13"
                  height="20"
                  viewBox="0 0 13 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 20L13 10L0 0V20Z"></path>
                </svg>
              </i>
            </button>
            <div className="textinner">
              <h1>Stickers Pack </h1>

              <div className="blowsectiom">
                <h4>
                  Show project <div className="line"></div>{" "}
                </h4>
                <h5>Identity, Mockup</h5>
              </div>
            </div>
          </div>

        </div>

      </div>














<div className="sectionThreeLabel"> 
<div data-vc-full-width="true" data-vc-full-width-init="true" class="vc_row wpb_row vc_row-fluid" ><div class="wpb_column vc_column_container vc_col-sm-5"><div class="vc_column-inner"><div class="wpb_wrapper"><div class="vc_empty_space  clb__section_spacer"><span class="vc_empty_space_inner"></span></div><div class="ohio-widget heading -left" id="ohio-custom-64adad5611ba3">
	
	
			<div class="subtitle">
			Our clients		</div>

					<div class="divider"></div>
		
	
	<h3 class="title">
				Some friends <br class="vc_hidden-sm vc_hidden-xs"/>
we have made while <br class="vc_hidden-sm vc_hidden-xs"/>working together.					</h3>

	
		
</div><div class="vc_empty_space"><span class="vc_empty_space_inner"></span></div><div class="vc_empty_space  vc_hidden-xs vc_hidden-sm" ><span class="vc_empty_space_inner"></span></div></div></div></div><div class="wpb_column vc_column_container vc_col-sm-1"><div class="vc_column-inner"><div class="wpb_wrapper"></div></div></div><div class="wpb_column vc_column_container nznxcmnz vc_col-sm-6"><div class="vc_column-inner"><div class="wpb_wrapper"><div class="vc_empty_space  clb__section_spacer vc_hidden-xs vc_hidden-sm"><span class="vc_empty_space_inner"></span></div><div class="ohio-text-sc" id="ohio-custom-64adad56125b2">
	<p>Our clients describe us as a product team which <strong>creates amazing</strong> UI/UX experiences, by crafting top-notch user experience.</p>
</div><div class="vc_empty_space  clb__spacer" ><span class="vc_empty_space_inner"></span></div><div class="vc_row wpb_row  vc_inner vc_row-fluid"><div class="wpb_column jjjk vc_column_container vc_col-sm-4 vc_col-xs-6"><div class="vc_column-inner vc_custom_1635342415882"><div class="wpb_wrapper"><div class="ohio-widget logo -left" id="ohio-custom-64adad56134b5">

			<a class="-unlink" href="#" target="_blank">
	
	<img src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/LogoSC-min.png" srcset="" sizes="(max-width: 268px) 100vw, 268px" alt=""/>

	
			</a>
	
</div><div class="vc_empty_space  vc_hidden-xs vc_hidden-sm" ><span class="vc_empty_space_inner"></span></div><div class="ohio-widget logo -left" id="ohio-custom-64adad5616acc">

			<a class="-unlink" href="#" target="_blank">
	
	<img src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/LogoSC-1-min.png" srcset="" sizes="(max-width: 268px) 100vw, 268px" alt=""/>

	
			</a>
	
</div><div class="vc_empty_space  vc_hidden-xs vc_hidden-sm" ><span class="vc_empty_space_inner"></span></div><div class="ohio-widget logo -left" id="ohio-custom-64adad561780c">

			<a class="-unlink" href="#" target="_blank">
	
	<img src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/LogoSC-6-min.png" srcset="" sizes="(max-width: 268px) 100vw, 268px" alt=""/>

	
			</a>
	
</div></div></div></div><div class="wpb_column vc_column_container jjjk vc_col-sm-4 vc_col-xs-6"><div class="vc_column-inner vc_custom_1571211607830"><div class="wpb_wrapper"><div class="ohio-widget logo -left" id="ohio-custom-64adad5618635">

			<a class="-unlink" href="#" target="_blank">
	
	<img src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/LogoSC-2-min.png" srcset="" sizes="(max-width: 268px) 100vw, 268px" alt=""/>

	
			</a>
	
</div><div class="vc_empty_space  vc_hidden-xs vc_hidden-sm" ><span class="vc_empty_space_inner"></span></div><div class="ohio-widget logo -left" id="ohio-custom-64adad5619156">

			<a class="-unlink" href="#" target="_blank">
	
	<img src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/LogoSC-3-min.png" srcset="" sizes="(max-width: 268px) 100vw, 268px" alt=""/>

	
			</a>
	
</div><div class="vc_empty_space  vc_hidden-xs vc_hidden-sm" ><span class="vc_empty_space_inner"></span></div><div class="ohio-widget logo -left" id="ohio-custom-64adad5619c03">

			<a class="-unlink" href="#" target="_blank">
	
	<img src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/LogoSC-7-min.png" srcset="" sizes="(max-width: 268px) 100vw, 268px" alt=""/>

	
			</a>
	
</div></div></div></div><div class="wpb_column vc_column_container jjjk vc_col-sm-4 vc_col-xs-6"><div class="vc_column-inner vc_custom_1571211612193"><div class="wpb_wrapper"><div class="ohio-widget logo -left" id="ohio-custom-64adad561a756">

			<a class="-unlink" href="#" target="_blank">
	
	<img src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/LogoSC-4-min.png" srcset="" sizes="(max-width: 268px) 100vw, 268px" alt=""/>

	
			</a>
	
</div><div class="vc_empty_space  vc_hidden-xs vc_hidden-sm" ><span class="vc_empty_space_inner"></span></div><div class="ohio-widget logo -left" id="ohio-custom-64adad561b1ba">

			<a class="-unlink" href="#" target="_blank">
	
	<img src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/LogoSC-5-min.png" srcset="" sizes="(max-width: 268px) 100vw, 268px" alt=""/>

	
			</a>
	
</div></div></div></div></div><div class="vc_empty_space  clb__section_spacer"><span class="vc_empty_space_inner"></span></div></div></div></div></div>
</div>



{/*  */}


<div className="carosuleSection">
  <div className="propscal">
    <img src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/oh__avatar1-min.png"/>
 <div className="labeltect">Customer Support</div>
 <p>“It's totally customizable with the theme settings, its compatible with a tonne of important plugins and the support is outstanding.”</p>
 <h1 className="texttiltei">Colin Lucido</h1>
 <h2 className="lineprops">Co-founder, Colabrio</h2>
 <div className="arrowBreakDown">
<div className="divsectionaligment">
<div className="OneLineText">1</div>
<div className="lineBorderTextd"> </div>
<div className="OneLineText">3</div>
</div>
<div className="divsectionaligment">
<i class="icon">
  <svg
style={{
  fill:"white"
}}
class="default" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"></path></svg><svg class="minimal" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 8C0 7.58579 0.335786 7.25 0.75 7.25H17.25C17.6642 7.25 18 7.58579 18 8C18 8.41421 17.6642 8.75 17.25 8.75H0.75C0.335786 8.75 0 8.41421 0 8Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M9.96967 0.71967C10.2626 0.426777 10.7374 0.426777 11.0303 0.71967L17.7803 7.46967C18.0732 7.76256 18.0732 8.23744 17.7803 8.53033L11.0303 15.2803C10.7374 15.5732 10.2626 15.5732 9.96967 15.2803C9.67678 14.9874 9.67678 14.5126 9.96967 14.2197L16.1893 8L9.96967 1.78033C9.67678 1.48744 9.67678 1.01256 9.96967 0.71967Z"></path></svg></i>
<i class="icon"
style={{
  transform:"rotate(180deg)"
}}
>
  <svg
style={{
  fill:"white"
}}
class="default" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"></path></svg><svg class="minimal" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 8C0 7.58579 0.335786 7.25 0.75 7.25H17.25C17.6642 7.25 18 7.58579 18 8C18 8.41421 17.6642 8.75 17.25 8.75H0.75C0.335786 8.75 0 8.41421 0 8Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M9.96967 0.71967C10.2626 0.426777 10.7374 0.426777 11.0303 0.71967L17.7803 7.46967C18.0732 7.76256 18.0732 8.23744 17.7803 8.53033L11.0303 15.2803C10.7374 15.5732 10.2626 15.5732 9.96967 15.2803C9.67678 14.9874 9.67678 14.5126 9.96967 14.2197L16.1893 8L9.96967 1.78033C9.67678 1.48744 9.67678 1.01256 9.96967 0.71967Z"></path></svg></i>
</div>
 </div>
  </div>
</div>




<div className="sectionThreeLabel"> 
<div data-vc-full-width="true" data-vc-full-width-init="true" class="vc_row wpb_row vc_row-fluid" ><div class="wpb_column vc_column_container vc_col-sm-5"><div class="vc_column-inner"><div class="wpb_wrapper"><div class="vc_empty_space  clb__section_spacer"><span class="vc_empty_space_inner"></span></div><div class="ohio-widget heading -left" id="ohio-custom-64adad5611ba3">
	
	
			<div class="subtitle">
			What we do	</div>

					<div class="divider"></div>
		
	
	<h3 class="title">
				Some friends <br class="vc_hidden-sm vc_hidden-xs"/>
we have made while <br class="vc_hidden-sm vc_hidden-xs"/>working together.					</h3>

	
		
</div><div class="vc_empty_space"><span class="vc_empty_space_inner"></span></div><div class="vc_empty_space  vc_hidden-xs vc_hidden-sm" ><span class="vc_empty_space_inner"></span></div></div></div></div><div class="wpb_column vc_column_container vc_col-sm-1"><div class="vc_column-inner"><div class="wpb_wrapper"></div></div></div><div class="wpb_column vc_column_container nznxcmnz vc_col-sm-6"><div class="vc_column-inner"><div class="wpb_wrapper"><div class="vc_empty_space  clb__section_spacer vc_hidden-xs vc_hidden-sm"><span class="vc_empty_space_inner"></span></div><div class="ohio-text-sc" id="ohio-custom-64adad56125b2">
	<p>Our clients describe us as a product team which <strong>creates amazing</strong> UI/UX experiences, by crafting top-notch user experience.</p>
</div><div class="vc_empty_space  clb__spacer" ><span class="vc_empty_space_inner"></span></div><div class="vc_row wpb_row vc_inner vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-4 vc_col-xs-6"><div class="vc_column-inner vc_custom_1635342415882"><div class="wpb_wrapper"><div class="ohio-widget logo -left" id="ohio-custom-64adad56134b5">


	
</div><div class="vc_empty_space  vc_hidden-xs vc_hidden-sm" ><span class="vc_empty_space_inner"></span></div><div class="ohio-widget logo -left" id="ohio-custom-64adad5616acc">

	
</div><div class="vc_empty_space  vc_hidden-xs vc_hidden-sm" ><span class="vc_empty_space_inner"></span></div><div class="ohio-widget logo -left" id="ohio-custom-64adad561780c">

		
	
</div></div></div></div><div class="wpb_column vc_column_container vc_col-sm-4 vc_col-xs-6"><div class="vc_column-inner vc_custom_1571211607830"><div class="wpb_wrapper"><div class="ohio-widget logo -left" id="ohio-custom-64adad5618635">

		
	
</div><div class="vc_empty_space  vc_hidden-xs vc_hidden-sm" ><span class="vc_empty_space_inner"></span></div><div class="ohio-widget logo -left" id="ohio-custom-64adad5619156">

	
	
</div><div class="vc_empty_space  vc_hidden-xs vc_hidden-sm" ><span class="vc_empty_space_inner"></span></div><div class="ohio-widget logo -left" id="ohio-custom-64adad5619c03">

			
	
</div></div></div></div><div class="wpb_column vc_column_container vc_col-sm-4 vc_col-xs-6"><div class="vc_column-inner vc_custom_1571211612193"><div class="wpb_wrapper"><div class="ohio-widget logo -left" id="ohio-custom-64adad561a756">

		
	
</div><div class="vc_empty_space  vc_hidden-xs vc_hidden-sm" ><span class="vc_empty_space_inner"></span></div><div class="ohio-widget logo -left" id="ohio-custom-64adad561b1ba">

		
	
</div></div></div></div></div><div class="vc_empty_space  clb__section_spacer"><span class="vc_empty_space_inner"></span></div></div></div></div></div>
</div>


<div className="ImgSection">
<div className="insideSectionNine">

</div>

<div className="leftsidening"> 
<button class="icon-button -large">
            <i class="icon">
              <svg
                class="default"
                width="13"
                height="20"
                viewBox="0 0 13 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 20L13 10L0 0V20Z"></path>
              </svg>
            </i>
          </button>
          <h3 class="title">
				We believe that our <br class="vc_hidden-xs vc_hidden-sm"/>works can contribute <br class="vc_hidden-xs vc_hidden-sm"/>to a better world.					</h3>



        <div className="textdic">
          
        Website & Mobile App Design    	
        </div>
        <div className="textdic">
          
          Website & Mobile App Design    	
          </div>
           <div className="textdic">
          
          Website & Mobile App Design    	
          </div>
</div>

  </div>


  <div className="inputSection">
    <div className="maintopsectio">
      <div className="insectiontoninput">
<h1>
			Say hello		</h1>
<h2>
				Request a free quote					</h2>
    <div className="mainconinterinput">
<div className="leftinpit">
<p> Name (required)</p>
<input placeholder="Your Name"/>
<p> Email (required)</p>
<input placeholder="Your Email"/>
<p> Subject (optional)</p>
<input placeholder="Choose Subject"/>
    </div>


    <div className="leftinpit">
<p> Company (required)</p>
<input placeholder="Your Name"/>
<p> Phone (required)</p>
<input placeholder="Your Email"/>
<p> Subject (optional)</p>
<input placeholder="Choose Subject"/>
    </div>
    </div>
      </div>

      <div className="touchdata">
        <h1>
			Get in touch		</h1>
      <h2>
				Email us					</h2>
        <p>For project inquiries only:<br/>
<strong>ask@colabrio.com</strong></p>

<p>For other questions:<br/>
<strong>hola@colabrio.com</strong></p>
      </div>

      </div>
      </div>



<div className="footer">

<div className="popswid">
<img src="https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-stage-demo-8/Fqj9jNnL-OhioLogoInverse.svg"/>
<li id="block-7" class="widget widget_block"><strong><a target="_blank" href="https://www.facebook.com/colabrio/">Fb.</a>&nbsp;/&nbsp;&nbsp;<a target="_blank" href="https://www.facebook.com/colabrio/">Ig.</a>&nbsp;&nbsp;/&nbsp;&nbsp; <a target="_blank" href="https://www.facebook.com/colabrio/">Tw.</a>&nbsp;&nbsp;/&nbsp;&nbsp; <a target="_blank" href="https://www.facebook.com/colabrio/">Be.</a></strong></li>
</div>

<div className="popswid">
  <div className="title">Rotterdam</div>
  <div className="sub">Ohio Digital Media LTD.</div>
  <div className="subinner">
    
Graaf Florisstraat 22A, <br/>

3021 CH Rotterdam
<br/>

Netherlands
  </div>
<br/>
<br/>
<br/>

  <div className="title">Rotterdam</div>
  <div className="sub">Ohio Digital Media LTD.</div>
  <div className="subinner">
    
Graaf Florisstraat 22A, <br/>

3021 CH Rotterdam
<br/>

Netherlands
  </div>
</div>


<div className="popswid">
<div className="title">Work inquiries</div>
  <div className="sub">

Interested in working with us?</div>
  <div className="subinner">
    
  hello@clbthemes.com <br/>
  </div>

  <div className="title">Work inquiries</div>
  <div className="sub">

Interested in working with us?</div>
  <div className="subinner">
    
  hello@clbthemes.com <br/>
  </div>


</div>


<div className="popswid">
<div className="title">Sign up for the newsletter</div>
<input placeholder="Email address" />

  

</div>
</div>



      
      </div>



    </>
  );
}
