import React from "react";
import "./Holiding.scss";
import Navbar from "./Navbar";

import logo1 from "../Image/logo/web3.png";
import upper from "../Image/logo/upper.svg";
import small1 from "../Image/nsmall1.png";
import small2 from "../Image/nsmall1.png";



export default function Holiding({setshow}) {

  return (
    <>
      <Navbar />
      <div className="HoldingPage">
        <h1>Our Holdings</h1>

        <div className="titleMain">
          <div className="inner">
            <img src={logo1} />
            <h2>Web3Today</h2>
          </div>
          <img src={upper} onClick={()=>setshow("default")} />
        </div>

        <div className="title">
          <div className="tileName">Company</div>
          <div className="tileName">Overview</div>
          <div className="tileName">Status</div>
          <div className="tileName">Position</div>
          <div className="tileName">Website</div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img
                src={logo1}
                style={{
                  height: "35px",
                }}
              />
              <h6>Web3Today</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A news company that covers all things related to crypto, blockchain and Web3 while enabling a innovative job marketplace.
            </p>
          </div>
          <div className="contentItem">
            <h5>Development</h5>
          </div>
          <div className="contentItem">
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>

        

        
      </div>
    </>
  );
}
