import React, { memo, useContext, useEffect, useState, useMemo } from "react";
// import { Agency } from '../../context/Context';
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Marker,
  Geography,
} from "react-simple-maps";
//import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";
//import sortBy from "lodash/sortBy";
import { MainContext } from "../Component/Context";
import axios from "axios";
const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const rounded = (num) => {
  if (num > 1000000000) {
    return Math.round(num / 100000000) / 10 + "Bn";
  } else if (num > 1000000) {
    return Math.round(num / 100000) / 10 + "M";
  } else {
    return Math.round(num / 100) / 10 + "K";
  }
};

const MapChart = ({ setTooltipContent }) => {
  const { setflag, flag, setflagimage, setmodelmap, setflagname } =
    useContext(MainContext);
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [clickedCity, setClickedCity] = useState("");
  //   const agency = useContext(Agency)
  //   const { compainesvalue, flitermapfunction } = agency;
  const handleClick = (geo) => {
    setClickedCity(geo.properties.name);
  };

  console.log("123123132", maxValue);
  const markers = [
    {
      markerOffset: 25,
      name: "",
      coordinates: [77.06971, 28.679079],
    },
    {
      markerOffset: 25,
      name: "",
      coordinates: [-95.695, 55.424721],
    },
    {
      markerOffset: 25,
      name: "",
      coordinates: [129.128998, -25.282001],
    },
    {
      markerOffset: 25,
      name: "",
      coordinates: [-0.118092, 51.509865],
    },
    {
      markerOffset: 25,
      name: "",
      coordinates: [-3.70379, 40.416775],
    },
  ];

  const colorScale = scaleLinear()
    .domain([0, 100000000, 1338612970]) // Max is based on China
    .range(["#FFF176", "#FFC107", "#E65100"]);

  useEffect(() => {
    flagfunction();
  }, []);
  const flagfunction = async () => {
    let data = await axios.get(
      "https://countriesnow.space/api/v0.1/countries/flag/images"
    );
    if (data.data.status) {
      setflag(data.data.data);
    }
  };

  const flitermapfunction = (e) => {
    let n1 = flag.filter((user) => {
      return user.flag.toUpperCase().includes(e.toUpperCase());
    });
    setflagimage(n1);
    setflagname(e);
    // this.setState({
    //   flagimage: n1,
    //   flagname: e,
    // });
    if (
      e == "Canada" ||
      e == "India" ||
      e == "Australia" ||
      e == "Spain" ||
      e == "United Kingdom"
    ) {
      setmodelmap(true);
      //   this.setState({
      //     modelmap: true,
      //   });
    }
  };

  return (
    <>
      <ComposableMap data-tip="">
        {/* <ZoomableGroup> */}
        <Geographies geography={geoUrl}>
          {({ geographies, projection }) =>
            geographies.map((geo) => {
              console.log("zcxczxcbfdf", geo.properties);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  //  fill={"India" ? "url('#lines')" : "#B8B9BA"}

                  projection={projection}
                  onMouseEnter={() => {
                    const { name, POP_EST } = geo.properties;

                    setTooltipContent(
                      name == "Canada" ||
                        name == "India" ||
                        name == "Australia" ||
                        name == "Spain" ||
                        name == "United Kingdom"
                        ? `${name}`
                        : ""
                    );
                  }}
                  onClick={() => {
                    const { name, POP_EST } = geo.properties;
                    flitermapfunction(`${name}`);
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                  width={800}
                  height={400}
                  style={{
                    default: {
                      fill: "#B8B9BA",
                      outline: "none",
                    },
                    hover: {
                      fill: "#182542",
                      outline: "none",
                    },
                    pressed: {
                      fill: "#182542",
                      stroke: "#607D8B",
                      strokeWidth: 1,
                      outline: "none",
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
        {/* </ZoomableGroup> */}
        {markers.map(({ name, coordinates, markerOffset }) => (
          <Marker key={name} coordinates={coordinates}>
            <circle r={5} fill="#182542" stroke="#fff" strokeWidth={3} />
            <text
              textAnchor="middle"
              y={markerOffset}
              style={{ fontFamily: "system-ui", fill: "#182542" }}
            >
              {name}
            </text>
          </Marker>
        ))}
      </ComposableMap>
    </>
  );
};

export default memo(MapChart);
