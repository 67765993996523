import React, { Component, createContext, useState } from "react";

export const MainContext = createContext();

const MainContextProvider = ({ children }) => {
  const [opencard, setopencard] = useState("translateY(-140%)");
  const [therightcard, settherightcard] = useState("translateY(-140%)");
  const [closecard, setclosecard] = useState("");
  const [pagemask, setpagemask] = useState("");
  const [closedisp, setclosedisp] = useState("none");
  const [closerightdisp, setcloserightdisp] = useState("none");
  const [icondisp, seticondisp] = useState("none");
  const [opemodel, setopenmodel] = useState(false);
  const [navName, setnavName] = useState("About");
  const [openholding, setopenholding] = useState("default");
  const [flagimage, setflagimage] = useState("");
  const [flag, setflag] = useState([]);
  const [modelmap, setmodelmap] = useState(false);
  const [flagname, setflagname] = useState("");
  console.log("setopenmodel", opemodel);

  const value = {
    flag,
    opencard,
    setflag,
    closecard,
    flagname,
    pagemask,
    setflagname,
    setmodelmap,
    modelmap,
    therightcard,
    closedisp,
    closerightdisp,
    icondisp,
    setopenmodel,
    setopencard,
    setnavName,
    navName,
    opemodel,
    setclosecard,
    setpagemask,
    settherightcard,
    setclosedisp,
    setcloserightdisp,
    seticondisp,
    setopenholding,
    openholding,
    flagimage,
    setflagimage,
  };

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};

export default MainContextProvider;

export const MainContextConsumer = MainContext.Consumer;
