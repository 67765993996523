import We1 from "../Image/we1.png"
import We2 from "../Image/we2.png"
import We3 from "../Image/we3.png"
import We4 from "../Image/we4.png"
import checkmark from "../Image/checkmark.png"
import inr from "../Image/inr.png"
import b1 from "../Image/b1.png";
import b2 from "../Image/b2.png";
import b3 from "../Image/b3.png";
import b4 from "../Image/b4.png";
import b5 from "../Image/b5.png";
import b6 from "../Image/b6.png";
import INRGroupIcon from "../Image/INRgroupIcon.png";
import IndianOTCIcon from "../Image/IndianOtcIcon.png";
import TaxChainsIcon from "../Image/TaxChainsIcon.png";
import IndianNightsIcon from "../Image/IndianNightsIcon.png";
import ConnectionIcon from "../Image/ConnectionIcon.png";
import rightside from "../Image/rightside.png"
import leftside from "../Image/leftside.png"
import gal from "../Image/gal.png";
import checkmobile from "../Image/check-image.png"
export default {
    b1, b2, b3, b4, b5, b6, IndianOTCIcon, INRGroupIcon, TaxChainsIcon, ConnectionIcon, IndianNightsIcon, rightside, leftside,
    We1, gal,
    inr,
    We2,
    We3,
    We4,
    checkmark,
    checkmobile
}