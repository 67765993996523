import React, { useEffect ,useState, useContext, useRef } from 'react'
import './Carer.scss'
import "./topcard.scss"
import te from '../Image/te.png'
import re from '../Image/re.png'
import NvestIcon from "../Image/nvesticon.svg";
import DotsIcon from "../Image/groupdotsicon.svg";
import MailIcon from "../Image/mailicon.svg";
import IndIcon from "../Image/indicon.svg";
import CloseIcon from "../Image/closeicon.svg";
import IndianOTCIcon from "../Image/indianotcicon.svg";
import TaxChainIcon from "../Image/taxchainicon.svg";
import IndianInvestorIcon from "../Image/indianinvestoricon.svg";
import IndiaCoinsIcon from "../Image/indiacoinsicon.svg";
import IndianMarketIcon from "../Image/metaverseicon.svg"
import BhararTrustIcon from "../Image/bharattrusticon.svg";
import InstaIcon from "../Image/instasvg.svg";
import DiscordIcon from "../Image/discordsvg.svg";
import YoutubeIcon from "../Image/youtubesvg.svg";
import EmailIcon from "../Image/mailsvg.svg"
import { MainContext } from './Context'
import MainContextProvider from './Context'
import TopCard from './topcard'
import NvestCard from "../Image/nvestico.svg"

export default function Carer() {
  const { icondisp, setopencard, setpagemask, settherightcard, setclosedisp, setcloserightdisp, seticondisp } = useContext(MainContext)
  const wrapperRef = useRef();

  useOutsideAlerter(wrapperRef);




  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */

      function handleClickOutside(event) {
        // console.log(ref.current, event.target, "kwjbfkwjbefc");
        if (ref.current && !ref.current.contains(event.target)) {
          // setopencard("translateY(-140%)")
          // setpagemask("")
          // setclosedisp("none")
          // settherightcard("translateY(-140%)")
          // setcloserightdisp("none")
          seticondisp("none")
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }


  const openmenu = (e) => {
    setpagemask("pagemask")
    setopencard("translateX(0%)")
    setclosedisp("")
  }


  const openrightcard = (e) => {
    settherightcard("translateX(0%)")
    setpagemask("pagemask")
    setcloserightdisp("")
  }

  const openico = (e) => {
    seticondisp("")
  }




  return (
    <div className='carres'>
      <div className="nvest-card" style={{ display: icondisp }} ref={wrapperRef}>
        <img src={NvestCard} alt="nvest-icon" className='nvest-img' onClick={(e) =>
          window.open("http://nvest.group", "_blank")
        } />
      </div>

      <img src={NvestIcon} alt="nvest-icon" className='left-icon' onMouseEnter={openico} onClick={(e) =>
          window.open("http://nvest.group", "_blank")
        }/>
      <div className='vl-top'></div>
      <img src={DotsIcon} alt="dots-icon" className='left-icon' onClick={openmenu} />
      <div className='vl-top'></div>

      <div className='right-icons'>
        <div className='vl-top1'></div>
        <img src={MailIcon} alt="mail-icon" className='right-logo' onClick={openrightcard} />
        <div className='vl-top1'></div>
        <img src={IndIcon} alt="ind-icon" className='right-logo' />
      </div>
      <TopCard />
      {/* <h5 style={{ fontWeight: "bold", marginRight: "35px" }}><img src={te} style={{ marginRight: '10px' }} />For Investors
      </h5>
      <h5 style={{ opacity: 0.5 }}><img src={re} style={{ marginRight: '10px' }} />For Entrepreneurs</h5> */}






      {/* <div className={pagemask}></div>

      <div className='sidemenu' style={{ transform: isMenuOpened }}>
        <div className='side-text'>
          <img src={CloseIcon} alt="close-icon" className='close-img' onClick={closemenu} style={{ display: closedisp }} />
          <p className="card-title">One Account. Mutiple Advantages</p>
          <p className='card-desc'>With An IndianOTC Account You Also Get Access To The INR Group Apps</p>
          {carddata.map(e => {
            return (
              <>
                <br />
                <div className='card-data'>
                  <img src={e.image} alt="indian-otc" className='company-logo' />
                  <div className='card-text'>
                    <p className='comp-title'>{e.title}</p>
                    <p className='comp-desc'>{e.desc}</p>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
      <div className='rightmenu' style={{ transform: RightCard }}>
        <img src={CloseIcon} alt="close-icon" className='close-img-right' onClick={rightclose} style={{ display: theicon }} />
        <div className='side-text'>
          <p className="right-card-title">Connect With Us</p>
          <p className='right-card-desc'>We Would Love To Hear From You</p>
          {rightdata.map(e => {
            return (
              <>
                <br />
                <div className='card-data'>
                  <img src={e.image} alt="indian-otc" className='company-logo' />
                  <div className='card-text'>
                    <p className='comp-title'>{e.title}</p>
                    <p className='comp-desc'>{e.desc}</p>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div> */}


    </div>
  )
}
