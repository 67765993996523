import React from "react";
import "./Holiding.scss";
import Navbar from "./Navbar";
import logo1 from "../Image/inner7.png";
import upper from "../Image/logo/upper.svg";

import small1 from "../Image/tokenobx.png";
import small2 from "../Image/tsmall2.png";
import small3 from "../Image/tsmall3.png";
import small4 from "../Image/tsmall4.png";
import small5 from "../Image/tsmall5.png";

export default function Holiding({setshow}) {
  return (
    <>
      <Navbar />
      <div className="HoldingPage">
        <h1>Our Holdings</h1>

        <div className="titleMain">
          <div className="inner">
            <img src={logo1} />
            <h2>T5 Group</h2>
          </div>
          <img src={upper} onClick={()=>setshow("default")} />
        </div>

        <div className="title">
          <div className="tileName">Company</div>
          <div className="tileName">Overview</div>
          <div className="tileName">Status</div>
          <div className="tileName">Position</div>
          <div className="tileName">Website</div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img
                src={small1}
                style={{
                  height: "35px",
                }}
              />
              <h6>TokenSwap</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            Formerly IndianOTC, TokenSwap.io is the easiest way to buy and sell crypto in India.
            </p>
          </div>
          <div className="contentItem">
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small2} />
              <h6>TokenOptions</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
              TokenOptions aims to be India’s first compliant derivatives
              platform for crypto and tokenized assets.
            </p>
          </div>
          <div className="contentItem">
            <h5>Development</h5>
          </div>
          <div className="contentItem">
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small3} />
              <h6>Terminals</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
              Is a “Exchange-As-A-Service” platform which allows anyone to
              launch their own branded cryptocurrency exchange.
            </p>
          </div>
          <div className="contentItem">
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small4} />
              <h6>Tellers</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
              A remittence application which allows people to send money to
              India and earn profit on each transfer.
            </p>
          </div>
          <div className="contentItem">
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small5} />
              <h6>Web3Today</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
              A news company that covers all things related to crypto,
              blockchain and Web3 while enabling a innovative job marketplace.
            </p>
          </div>
          <div className="contentItem">
            <h5>Development</h5>
          </div>
          <div className="contentItem">
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>
      </div>
    </>
  );
}
