import React, { useState, useEffect } from 'react'
import "./CoreBrand.scss"
import axios from "axios";
import invest from "../Static/Image/invest.png"
import core from "../Static/Image/core.png"
import Members from "./CoreMember"
import Nav from './Navabar'
import Banner from './Carer'
import OtpModel from './OtpModel';
export default function CoreBrand() {
    const [collection, setcollection] = useState([])
    const data = [
        {
            title: "For Investors",
            site: "IndianInvestor.com",
            boldtitle: "Starter",
            des: "Is Our In-House Startup Inbucation Program That Allows For Aspiring Entrepreneurs To Lead The Companies That Will Shape The India’s Future."
        },

    ]
    useEffect(() => {

        axios
            .get("https://comms.globalxchange.io/gxb/app/gxlive/operator/brands/get?parent_group_id=inrgroup")
            .then((res) => {
                setcollection(res.data.brands)

            });

    }, [collection]);
    return (
        <>
            <Banner />
            <Nav active="Contact" />
            <Members
                thedisp={""}
            />
            <section id="Direct" key="1">

                <h1 style={{paddingTop:"400px",textAlign:"center"}}>Coming Soon</h1>


            </section>

            <OtpModel />
        </>
    )
}
