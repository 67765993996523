import React, { useContext } from 'react'
import './Carer.scss'
import "./topcard.scss"
import CloseIcon from "../Image/closeicon.svg";
import IndianOTCIcon from "../Image/indianotcicon.svg";
import TaxChainIcon from "../Image/taxchainicon.svg";
import IndianInvestorIcon from "../Image/indianinvestoricon.svg";
import IndiaCoinsIcon from "../Image/indiacoinsicon.svg";
import IndianMarketIcon from "../Image/metaverseicon.svg"
import BhararTrustIcon from "../Image/bharattrusticon.svg";
import InstaIcon from "../Image/instasvg.svg";
import DiscordIcon from "../Image/discordsvg.svg";
import YoutubeIcon from "../Image/youtubesvg.svg";
import EmailIcon from "../Image/mailsvg.svg"
import { MainContext } from './Context'

function TopCard() {
    const value = useContext(MainContext);

    let carddata = [
        {
            "image": IndianOTCIcon,
            "title": "IndianOTC",
            "desc": "Digital Asset Trading Hub"
        },
        {
            "image": TaxChainIcon,
            "title": "TaxChains",
            "desc": "Crypto Tax Software"
        },
        {
            "image": IndianInvestorIcon,
            "title": "IndianInvestor",
            "desc": "Wealth Management System"
        },
        {
            "image": IndiaCoinsIcon,
            "title": "IndiaCoins",
            "desc": "India`s NFT Marketplace"
        },
        {
            "image": IndianMarketIcon,
            "title": "IndianMarket",
            "desc": "Financial Media & News"
        },
        {
            "image": BhararTrustIcon,
            "title": "BharatTrust",
            "desc": "Social Investing Platform"
        },
    ]

    let rightdata = [
        {
            "image": InstaIcon,
            "title": "Instagram",
            "desc": "@inr.group"
        },
        {
            "image": DiscordIcon,
            "title": "Discord",
            "desc": "Join Server"
        },
        {
            "image": YoutubeIcon,
            "title": "Youtube",
            "desc": "Go To Channel"
        },
        {
            "image": EmailIcon,
            "title": "Email",
            "desc": "support@inr.group"
        },
    ]
    const closemenu = (e) => {
        value.setopencard("translateY(-140%)")
        value.setpagemask("")
        value.setclosedisp("none")
    }


    const rightclose = (e) => {
        value.settherightcard("translateY(-140%)")
        value.setpagemask("")
        value.setcloserightdisp("none")
    }
    const opensite = (e) => {
        console.log(e.currentTarget.id)
        switch (e.currentTarget.id) {
            case "IndianOTC":
                window.open("https://indianotc.com", "_blank")
                break;
            case "TaxChains":
                window.open("https://taxchains.com", "_blank")
                break;
            case "IndianInvestor":
                window.open("https://indianinvestor.com", "_blank")
                break;
            case "IndiaCoins":
                window.open("https://indiacoins.com", "_blank")
                break;
            case "IndianMarket":
                window.open("https://indian.market", "_blank")
                break;
            case "BharatTrust":
                window.open("https://bharattrust.com", "_blank")
                break;
        }

    }
    return (
        <>
            <div className={value.pagemask}></div>
            <div className='sidemenu' style={{ transform: value.opencard }} onMouseDown={(e) => e.stopPropagation()}>
                <div className='side-text'>
                    <img src={CloseIcon} alt="close-icon" className='close-img' onClick={closemenu} style={{ display: value.closedisp }} />
                    <p className="card-title">One Account. Mutiple Advantages</p>
                    <p className='card-desc'>With An IndianOTC Account You Also Get Access To The INR Group Apps</p>
                    {carddata.map(e => {
                        return (
                            <>
                                <br />
                                <div className='card-data' onClick={opensite} id={e.title}>
                                    <img src={e.image} alt="indian-otc" className='company-logo' />
                                    <div className='card-text'>
                                        <p className='comp-title'>{e.title}</p>
                                        <p className='comp-desc'>{e.desc}</p>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
            {/* rightmenu */}
            <div className='rightmenu' style={{ transform: value.therightcard }} onMouseDown={(e) => e.stopPropagation()}>
                <img src={CloseIcon} alt="close-icon" className='close-img-right' onClick={rightclose} style={{ display: value.closerightdisp }} />
                <div className='side-text'>
                    <p className="right-card-title">Connect With Us</p>
                    <p className='right-card-desc'>We Would Love To Hear From You</p>
                    {rightdata.map(e => {
                        return (
                            <>
                                <br />
                                <div className='card-data'>
                                    <img src={e.image} alt="indian-otc" className='company-logo' />
                                    <div className='card-text'>
                                        <p className='comp-title'>{e.title}</p>
                                        <p className='comp-desc'>{e.desc}</p>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default TopCard